/* DO NOT MODIFY, THIS FILE IS GENERATED BY THE TOGETAIR CLI.
THE TOGETAIR CLI IS COPYRIGHT MATERIAL OF TOGETAIR SPRL (togetair.com).
A COMMERCIAL LICENSE IS REQUIRED IN ORDER TO RUN THIS TOOLING.
CONTACT support@togetair.com IN CASE OF INQUIRY. */
var ___module_togetair = angular.module('togetair', ['ui.router']);;___module_togetair.name = "togetair";
function functionName(fun) {
  var ret = fun.toString();
  ret = ret.substr('function '.length);
  ret = ret.substr(0, ret.indexOf('('));
  return ret;
}
function camelToSnake(str) {
  return str.replace(/\W+/g, '-').replace(/([a-z\d])([A-Z])/g, '$1-$2').toLowerCase();
}
function snakeToCamel(str) {
  return str.replace(/\W+(.)/g, function(x, chr) {
    return chr.toUpperCase();
  });
}
function $__injector() {
  return angular.element(document.body).injector() || angular.injector();
}
function $__instantiate(clazz, locals) {
  return $__injector().instantiate(clazz, locals);
}
function $__invoke(fn, obj) {
  return $__injector().invoke(fn, obj);
}
var Service = function() {
  Service.$inject = ["base"];
  function Service(base) {
    
    if (base) {
      angular.extend(this, base);
      this.save = function(obj, params) {
        return new Promise(function(resolve, reject) {
          new base(obj).$save(params, resolve, reject);
        });
      };
    }
  }
  return ($traceurRuntime.createClass)(Service, {invoke: function(fn) {
      if (fn)
        return $__invoke(fn, this);
    }}, {register: function(name, service, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        service = name;
        name = functionName(name);
      }
      console.log('Registering service', name);
      angular.module(options.module || window.$APP_CONFIG.name || 'app').service(name, function() {
        return $__instantiate(service);
      });
    }});
}();
;
var Component = function() {
  function Component() {
    
  }
  return ($traceurRuntime.createClass)(Component, {
    invoke: function(fn) {
      if (fn)
        return $__invoke(fn, this);
    },
    go: function(state, params) {
      try {
        $__injector().get('tpxState').go(state, params);
      } catch (e) {
        $__injector().get('$state').go(state, params);
      }
    },
    trigger: function(fn) {
      if (fn)
        $__invoke(fn, this);
    },
    broadcast: function(event) {
      if (this.scope)
        this.scope.$broadcast(event);
    },
    emit: function(event) {
      if (this.scope)
        this.scope.$emit(event);
    },
    on: function(event, handler) {
      if (this.scope)
        this.scope.$on(event, handler);
    }
  }, {});
}();
var Directive = function($__super) {
  function Directive() {
    
    $traceurRuntime.superConstructor(Directive).call(this);
  }
  return ($traceurRuntime.createClass)(Directive, {}, {register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        options = clazz;
        clazz = name;
        name = functionName(clazz);
      }
      var directiveName = name.substr(0, 1).toLowerCase() + name.substr(1);
      var snake = camelToSnake(name);
      console.log('Registering directive', name);
      options = options || {};
      angular.module(options.module || window.$APP_CONFIG.name || 'app').directive(directiveName, function() {
        var controller = ['$scope', function($scope) {
          var instance = $__instantiate(clazz, {$scope: $scope});
          Object.assign(this, instance);
          Object.assign(this, $scope);
          this.__proto__ = clazz.prototype;
          $scope.view = this;
        }];
        if (options.restrict === 'E')
          options.templateUrl = snake + '.html';
        options.controllerAs = 'view';
        options.bindToController = true;
        options.controller = controller;
        options.link = function() {
          try {
            clazz.prototype.link.apply(clazz.prototype, arguments);
          } catch (e) {
            console.log('ERROR in directive', name, e);
          }
        };
        return options;
      });
    }}, $__super);
}(Component);
var Dialog = function($__super) {
  function Dialog() {
    
    var $__3,
        $__4,
        $__5;
    $traceurRuntime.superConstructor(Dialog).call(this);
    var injector = angular.element(document.body).injector();
    var modal = injector.get('$ionicModal');
    var scope = injector.get('$rootScope').$new(true);
    this.scope = scope;
    var template = camelToSnake(Object.getPrototypeOf(this).name) + '.html';
    scope.view = this;
    modal.fromTemplateUrl(template, {
      scope: scope,
      animation: 'slide-in-up'
    }).then(($__3 = this, function(modal) {
      $__3.modal = modal;
      scope.$emit('modal.loaded');
      injector.get('$rootScope').$on('$stateChangeSuccess', function() {
        injector.get('$timeout')(function() {
          $__3.hide();
        }, 600);
      });
    }));
    scope.$on('modal.shown', ($__4 = this, function() {
      return $__4.trigger($__4.onShow);
    }));
    scope.$on('modal.hidden', ($__5 = this, function() {
      return $__5.trigger($__5.onHide);
    }));
  }
  return ($traceurRuntime.createClass)(Dialog, {
    show: function() {
      var $__3 = this;
      if (!this.modal) {
        this.scope.$on('modal.loaded', function() {
          return $__3.modal.show();
        });
      } else {
        this.modal.show();
      }
      this.promise = new Promise(function(resolve, reject) {
        $__3.resolve = resolve;
        $__3.reject = reject;
      });
      return this.promise;
    },
    confirm: function(response) {
      this.resolve(response);
    },
    cancel: function() {
      this.reject();
    },
    hide: function() {
      this.modal.hide();
    }
  }, {register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        clazz = name;
        name = functionName(clazz);
      }
      console.log('Registering dialog', name);
      clazz.prototype.name = name;
      angular.module(options.module || window.$APP_CONFIG.name || 'app').service(name, clazz);
    }}, $__super);
}(Component);
;
var _listeners = {};
var View = function($__super) {
  View.$inject = ["$scope"];
  function View($scope) {
    
    $traceurRuntime.superConstructor(View).call(this);
    this.x = 3;
  }
  return ($traceurRuntime.createClass)(View, {}, {
    register: function(name, clazz, options) {
      if (!options)
        options = {};
      if (typeof(name) === "function") {
        options = clazz;
        clazz = name;
        name = functionName(clazz);
      }
      var snake = camelToSnake(name);
      console.log('Registering view', name);
      var cName = name + 'Controller';
      options = options || {};
      angular.module(options.module || window.$APP_CONFIG.name || 'app').controller(cName, ['$scope', '$rootScope', function($scope, $rootScope) {
        var $__3 = this;
        this.scope = $scope;
        var instance = $__instantiate(clazz, {$scope: $scope});
        Object.assign(this, instance);
        this.__proto__ = clazz.prototype;
        $scope.view = this;
        if (window.ionic) {
          $scope.$on('$ionicView.afterEnter', function(e, data) {
            var backBtnEle = document.querySelector('.back-button');
            if (backBtnEle && data) {
              var hasBack = data.enableBack && data.showBack;
              backBtnEle.classList[hasBack ? 'remove' : 'add']('hide');
            }
          });
          $scope.$on('$ionicView.beforeEnter', function() {
            return $__3.trigger($__3.beforeEnter);
          });
          $scope.$on('$ionicView.beforeLeave', function() {
            return $__3.trigger($__3.beforeLeave);
          });
          $scope.$on('$ionicView.afterEnter', function() {
            return $__3.trigger($__3.afterEnter);
          });
          $scope.$on('$ionicView.afterLeave', function() {
            return $__3.trigger($__3.afterLeave);
          });
        } else {
          $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            if (fromState.name === snake)
              instance.trigger(instance.beforeLeave);
            if (toState.name === snake)
              instance.trigger(instance.beforeEnter);
          });
          $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            if (fromState.name === snake)
              instance.trigger(instance.afterLeave);
          });
          $rootScope.$on('$viewContentLoading', function(event, viewConfig) {});
          $scope.$on('$viewContentLoaded', function(event) {
            if (instance.trigger)
              instance.trigger(instance.afterEnter);
          });
        }
      }]);
      if (!options || !options.noroute) {
        ___module_togetair.config(["$stateProvider", function($stateProvider) {
          $stateProvider.state(snake, {
            url: options.route || '/' + snake + (options.query ? '?' + options.query : ''),
            templateUrl: snake + '.html',
            controller: cName,
            controllerAs: 'view',
            bindToController: true
          });
        }]);
      }
      if (_listeners[name]) {
        var $__9 = true;
        var $__10 = false;
        var $__11 = undefined;
        try {
          for (var $__7 = void 0,
              $__6 = (_listeners[name])[Symbol.iterator](); !($__9 = ($__7 = $__6.next()).done); $__9 = true) {
            var i = $__7.value;
            i(clazz);
          }
        } catch ($__12) {
          $__10 = true;
          $__11 = $__12;
        } finally {
          try {
            if (!$__9 && $__6.return != null) {
              $__6.return();
            }
          } finally {
            if ($__10) {
              throw $__11;
            }
          }
        }
      } else {
        _listeners[name] = [];
        _listeners[name].registered = true;
      }
    },
    defaultRoute: function(route) {
      ___module_togetair.config(['$urlRouterProvider', function(urp) {
        urp.otherwise(route);
      }]);
    },
    get: function(clazz) {
      var name = functionName(clazz);
      var cName = name + 'Controller';
      return $__invoke([cName, function(ctrl) {
        return ctrl;
      }]);
    },
    onRegister: function(clazz, fn) {
      if (!_listeners[clazz])
        _listeners[clazz] = [];
      _listeners[clazz].push(fn);
      if (_listeners[clazz].registered) {
        var $__9 = true;
        var $__10 = false;
        var $__11 = undefined;
        try {
          for (var $__7 = void 0,
              $__6 = (_listeners[clazz])[Symbol.iterator](); !($__9 = ($__7 = $__6.next()).done); $__9 = true) {
            var i = $__7.value;
            i(clazz);
          }
        } catch ($__12) {
          $__10 = true;
          $__11 = $__12;
        } finally {
          try {
            if (!$__9 && $__6.return != null) {
              $__6.return();
            }
          } finally {
            if ($__10) {
              throw $__11;
            }
          }
        }
      }
      return function() {
        _listeners[clazz].remove(fn);
      };
    }
  }, $__super);
}(Component);
var Watcher = function() {
  Watcher.$inject = ["$rootScope"];
  function Watcher($rootScope) {
    
    
    this.scope = $rootScope.$new(true);
  }
  return ($traceurRuntime.createClass)(Watcher, {watch: function(fnWatch, fnDo) {
      if (Array.isArray(fnWatch)) {
        this.scope.arrays = this.scope.arrays || [];
        this.scope.arrays.push(array);
        var index = this.scope.arrays.length - 1;
        var w = this.scope.$watchCollection('arrays[' + index + ']', fnDo);
        return function() {
          w();
          this.scope.arrays.slide(index, 1);
        };
      } else {
        return this.scope.$watch(fnWatch, fnDo);
      }
    }}, {});
}();
___module_togetair.service('Watcher', Watcher);
;
___module_togetair.factory('GoogleAnalytics', ["$q", "$config", "$window", "$script", function($q, $config, $window, $script) {
  if ($config.mobile) {
    return {
      startTrackerWithId: function(id) {
        var d = $q.defer();
        $window.analytics.startTrackerWithId(id, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      setUserId: function(id) {
        var d = $q.defer();
        $window.analytics.setUserId(id, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      debugMode: function() {
        var d = $q.defer();
        $window.analytics.debugMode(function(response) {
          d.resolve(response);
        }, function() {
          d.reject();
        });
        return d.promise;
      },
      trackView: function(screenName) {
        var d = $q.defer();
        $window.analytics.trackView(screenName, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      addCustomDimension: function(key, value) {
        var d = $q.defer();
        $window.analytics.addCustomDimension(key, value, function() {
          d.resolve();
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      trackEvent: function(category, action, label, value) {
        var d = $q.defer();
        $window.analytics.trackEvent(category, action, label, value, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      addTransaction: function(transactionId, affiliation, revenue, tax, shipping, currencyCode) {
        var d = $q.defer();
        $window.analytics.addTransaction(transactionId, affiliation, revenue, tax, shipping, currencyCode, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      },
      addTransactionItem: function(transactionId, name, sku, category, price, quantity, currencyCode) {
        var d = $q.defer();
        $window.analytics.addTransactionItem(transactionId, name, sku, category, price, quantity, currencyCode, function(response) {
          d.resolve(response);
        }, function(error) {
          d.reject(error);
        });
        return d.promise;
      }
    };
  } else {
    window['GoogleAnalyticsObject'] = 'ga';
    window.ga = function() {
      window.ga.q.push(arguments);
    };
    window.ga.q = [];
    window.ga.l = 1 * new Date();
    $script('//www.google-analytics.com/analytics.js', null, true);
    return {
      startTrackerWithId: function(id) {
        window.ga('create', $config.googleAnalytics, 'auto');
      },
      setUserId: function(id) {
        window.ga('set', '&uid', id);
      },
      debugMode: function() {},
      trackView: function(screenName) {
        window.ga('send', 'screenview', {screenName: screenName});
      },
      addCustomDimension: function(key, value) {},
      trackEvent: function(category, action, label, value) {
        window.ga('send', 'event', category, action, label, value);
      },
      addTransaction: function(transactionId, affiliation, revenue, tax, shipping, currencyCode) {},
      addTransactionItem: function(transactionId, name, sku, category, price, quantity, currencyCode) {}
    };
  }
}]);
;
___module_togetair.factory('b64toBlob', function() {
  return function(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };
});
;
___module_togetair.factory('dataURItoBlob', function() {
  return function(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], {type: mimeString});
  };
});
;
___module_togetair.factory('imageLoader', function() {
  return function($scope, $element, url, loadingImg, errorImg) {
    var loadingDefault = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";
    $element[0].src = loadingImg ? loadingImg : loadingDefault;
    $element.addClass('image-loading');
    $element.src = $scope.ngSrc;
    $element[0].onerror = function() {
      $element.attr('src', errorImg);
    };
    $element.bind('load', function(e) {
      if (/^(f|ht)tps?:\/\//i.test($element.attr('src'))) {
        $element.removeClass('image-loading');
        $element.css('-webkit-animation', 'fadeIn ease-in 0.3s');
      }
      $element.attr('src', url);
    });
  };
});
;
___module_togetair.factory('$imageResizer', ["$q", "b64toBlob", "dataURItoBlob", function($q, b64toBlob, dataURItoBlob) {
  return function(fileOrURI, maxSize) {
    if (typeof fileOrURI == 'string') {
      return $q(function(resolve, reject) {
        var image = new Image();
        image.addEventListener('load', function() {
          var canvas = document.createElement('canvas');
          if (image.width > image.height) {
            canvas.width = Math.min(maxSize, image.width);
            canvas.height = image.height * (canvas.width / image.width);
          } else {
            canvas.height = Math.min(maxSize, image.height);
            canvas.width = image.width * (canvas.height / image.height);
          }
          var context = canvas.getContext('2d');
          context.imageSmoothingEnabled = true;
          context.webkitImageSmoothingEnabled = true;
          context.mozImageSmoothingEnabled = true;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        });
        var blob = b64toBlob(fileOrURI);
        resolve(blob);
        image.src = fileOrURI;
      });
    } else {
      return $q(function(resolve, reject) {
        var reader = new FileReader();
        reader.onload = function(e) {
          var image = new Image();
          image.addEventListener('load', function() {
            var canvas = document.createElement('canvas');
            if (image.width > image.height) {
              canvas.width = Math.min(maxSize, image.width);
              canvas.height = image.height * (canvas.width / image.width);
            } else {
              canvas.height = Math.min(maxSize, image.height);
              canvas.width = image.width * (canvas.height / image.height);
            }
            var context = canvas.getContext('2d');
            context.imageSmoothingEnabled = true;
            context.webkitImageSmoothingEnabled = true;
            context.mozImageSmoothingEnabled = true;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            var blob = dataURItoBlob(canvas.toDataURL());
            resolve(blob);
          });
          image.src = e.target.result;
        };
        reader.readAsDataURL(fileOrURI);
      });
    }
  };
}]);
;
___module_togetair.directive('imageSize', ["$parse", function($parse) {
  return {
    restrict: 'A',
    scope: false,
    link: function($scope, $element, $attrs) {
      var size = {};
      var setter = $parse($attrs.imageSize).assign;
      $attrs.$observe('imageSizeSrc', function(url) {
        if (url) {
          var myImage = new Image();
          myImage.name = url;
          myImage.onload = function() {
            size.height = this.height;
            size.width = this.width;
            setter($scope, size);
            return true;
          };
          myImage.src = url;
        }
      });
    }
  };
}]);
;
___module_togetair.directive('imageViewPopup', ["$document", "$ionicPlatform", function($document, $ionicPlatform) {
  var div,
      doUnregisterBackAction;
  return function($scope, $element, $attrs) {
    var doHide = function(e) {
      div.addClass('image-glasspane-hide');
      setTimeout(function() {
        div.css('background-image', '');
      }, 500);
      if (e)
        e.preventDefault();
      if (e)
        e.stopPropagation();
      doUnregisterBackAction();
      $document.find('body').off('click', doHide);
    };
    $element.on('click', function(e) {
      if (!div) {
        div = angular.element('<div>');
        div.addClass('image-glasspane');
        $document.find('body').eq(0).append(div);
      }
      $document.find('body').on('click', doHide);
      div.removeClass('image-glasspane-hide');
      div.css('background-image', 'url(' + $element[0].src + ')');
      doUnregisterBackAction = $ionicPlatform.registerBackButtonAction(doHide, 1000);
      e.preventDefault();
      e.stopPropagation();
    });
  };
}]);
;
___module_togetair.directive('imageRef', function() {
  return function(scope, element, attrs) {
    element.addClass(attrs.imageRef);
  };
}).filter('imageRef', function() {
  function getStyle(className) {
    var styleSheets = window.document.styleSheets;
    for (var i = 0,
        sL = styleSheets.length; i < sL; i++) {
      if (styleSheets[i].href && styleSheets[i].href.indexOf('images.css') > -1) {
        var classes = styleSheets[i].rules || styleSheets[i].cssRules;
        for (var x = 0,
            L = classes.length; x < L; x++) {
          if (classes[x].selectorText === className) {
            var ret = classes[x].cssText || classes[x].style.cssText;
            if (ret.indexOf(classes[x].selectorText) == -1) {
              ret = classes[x].selectorText + "{" + ret + "}";
            }
            return ret;
          }
        }
      }
    }
  }
  var cache = {};
  return function(ref) {
    if (ref) {
      if (!cache[ref]) {
        var rule = getStyle('.' + ref);
        if (rule) {
          cache[ref] = /url\(([^\)]*)\)/g.exec(rule)[1];
        } else {
          throw new Error('Could not find CSS rule in which a b64 image is expected to be defined: ' + ref);
        }
      }
      return cache[ref];
    } else {
      throw new Error('A CSS class reference is expected for the imageRef filter');
    }
  };
});
;
angular.toBoolean = function(value) {
  if (value && value.length !== 0) {
    var v = angular.lowercase("" + value);
    value = !(v == 'f' || v == '0' || v == 'false' || v == 'no' || v == 'n' || v == '[]');
  } else {
    value = false;
  }
  return value;
};
angular.addScript = function(filepath, callback, isAsync) {
  if (filepath) {
    var fileref = document.createElement('script');
    fileref.onload = callback;
    fileref.setAttribute("type", "text/javascript");
    fileref.setAttribute("src", filepath);
    if (isAsync)
      fileref.setAttribute("async", 1);
    if (typeof fileref != "undefined") {
      angular.element(document).ready(function() {
        document.getElementsByTagName("head")[0].appendChild(fileref);
      });
    }
  }
};
___module_togetair.config(["$compileProvider", function($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|geo):/);
}]).factory('templateUrl', function() {
  return function(name) {
    return name + '.html';
  };
}).factory('$poll', ["$timeout", function($timeout) {
  return function(tm, fn, initWait) {
    var _polling,
        polling = function() {
          fn();
          _polling = $timeout(polling, tm || 30000);
        };
    if (!initWait) {
      polling();
    } else {
      $timeout(function() {
        polling();
      }, tm);
    }
    return {cancel: function() {
        $timeout.cancel(_polling);
      }};
  };
}]).factory('$script', function() {
  return angular.addScript;
}).run(function() {
  window.path = function(o, p) {
    if (p == '.' || p == null)
      return o;
    var paths = p.split('.'),
        cur = o;
    for (var j = 0; j < paths.length; j++) {
      cur = cur[paths[j]];
      if (cur == null)
        return null;
    }
    return cur;
  };
  String.prototype.startsWith = function(str) {
    return this.indexOf(str) == 0;
  };
  String.prototype.hashCode = function() {
    var hash = 0,
        i,
        chr,
        len;
    if (this.length == 0)
      return hash;
    for (i = 0, len = this.length; i < len; i++) {
      chr = this.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  };
  if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function() {
      return this * Math.PI / 180;
    };
  }
  if (typeof(Number.prototype.toDeg) === "undefined") {
    Number.prototype.toDeg = function() {
      return this * 180 / Math.PI;
    };
  }
  Array.prototype.sortBy = function(p) {
    this.sort(function(a, b) {
      if (a[p] > b[p])
        return 1;
      if (a[p] < b[p])
        return -1;
      return 0;
    });
  };
  Array.prototype.contains = function(obj) {
    return this.indexOf(obj) > -1;
  };
  Array.prototype.last = function() {
    return this[this.length - 1];
  };
  Array.prototype.insert = function(obj) {
    if (!this.contains(obj))
      this.push(obj);
  };
  Array.prototype.toggle = function(obj) {
    this.contains(obj) ? this.remove(obj) : this.push(obj);
  };
  Array.prototype.remove = function(obj) {
    var index = this.indexOf(obj);
    if (index > -1)
      this.splice(index, 1);
  };
  Array.prototype.findOne = function(key, value) {
    for (var i = 0; i < this.length; i++) {
      if ('' + path(this[i], key) === '' + value)
        return this[i];
    }
  };
  Array.prototype.findSome = function(key, values) {
    return this.filter(function(e) {
      return values.indexOf(path(e, key)) > -1;
    });
  };
  Array.prototype.prepend = function(ar) {
    var args = ar.slice(0);
    args.unshift(0);
    args.unshift(0);
    Array.prototype.splice.apply(this, args);
    return this;
  };
  Array.prototype.append = function(ar) {
    var args = ar.slice(0);
    args.unshift(0);
    args.unshift(this.length);
    Array.prototype.splice.apply(this, args);
    return this;
  };
  Array.prototype.removeDuplicates = function(keyname) {
    var keys = [],
        o = [];
    var p = this.length - 1;
    while (p > -1) {
      var key = this[p][keyname];
      if (keys.indexOf(key) === -1) {
        keys.push(key);
        o.push(this[p]);
      }
      p--;
    }
    return o;
  };
  Array.prototype.binarySearch = function(value) {
    var l = 0,
        u = this.length,
        p;
    while (true) {
      p = (l + u) >> 1;
      if ((p === u || p === l) && this[p] !== value)
        return -1;
      if (this[p] > value) {
        u = p;
      } else if (this[p] < value) {
        l = p;
      } else {
        return p;
      }
    }
  };
});
